export const getUrlParam = (key, locationSearch = null) => {
	const encodedKey = decodeURIComponent(key);
	const urlParams = new URLSearchParams(locationSearch ?? window.location.search);
	const queryString = urlParams.get(encodedKey);
	return queryString ? decodeURIComponent(queryString) : "";
};

export const setUrlParam = (key, value) => {
	const url = new URL(window.location);
	url.searchParams.set(key, value);
	window.history.replaceState({}, "", url);
};

export const removeUrlParam = (key) => {
	const url = new URL(window.location);
	url.searchParams.delete(key);
	window.history.replaceState({}, "", url);
};

export const normalizeFilterParam = (param) => param.split(" ").join("_").toLowerCase();