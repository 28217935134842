export default function initPushOwl() {
	const subdomain = "shop-mod-lighting";
	const platform = "shopify";

	window.pushowl = window.pushowl || {
		queue: [],
		trigger: function (taskName, taskData) {
			return new Promise((resolve, reject) => {
				this.queue.push({
					taskName,
					taskData,
					promise: { resolve, reject },
				});
			});
		},
		init: function () {
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.src =
                "https://cdn.pushowl.com/sdks/pushowl-sdk.js?subdomain=" +
                subdomain +
                "&environment=production&shop=" +
                subdomain +
                ".my" +
                platform +
                ".com&platform=" +
                platform;

			const firstScript = document.getElementsByTagName("script")[0];
			firstScript.parentNode.insertBefore(script, firstScript);
		},
	};

	window.pushowl.subdomain = subdomain;
	window.pushowl.init();
}
