import CustomerResource from "./customer-resource";
import CartResource from "./cart-resource";
import axios from "axios";
import { SHOPIFY_STOREFRONT_TOKEN, SHOPIFY_DOMAIN } from "utils/constants";

/**
 * @class
 *
 * @property {CheckoutResource} checkout The property under which shop fetching and mutating methods live.
 */
class Client {
	constructor() {
		const axiosInstance = axios.create({
			baseURL: `https://${SHOPIFY_DOMAIN}/api/2024-04/graphql.json`,
			headers: {
				"Content-Type": "application/json",
				"X-Shopify-Storefront-Access-Token": SHOPIFY_STOREFRONT_TOKEN,
			},
		});
		axiosInstance.send = (query, variables) =>
			axiosInstance.post("", { query, variables })
				.then(response => {
					const data = { ...Object.values(response.data.data)[0] };
					if (response.data?.errors)
						data.errors = response.data.errors;

					return data;
				})
				.catch(error => {
					console.log(error);
					return null;
				});
		this.customer = new CustomerResource(axiosInstance);
		this.cart = new CartResource(axiosInstance);
	}
}

export default Client;