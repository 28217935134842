import { SITE_URL } from "utils/constants";

const mapItems = (items, list, isProduct) =>
	items.map((item, i) => {
		const variant = item?.variant || item?.variants?.[0] || {};
		let handle = `/products/${item?.handle}`;
		if (variant && item.variants?.length) {
			const variantHandle = item.variants[0].handle === variant.handle ? "" : `/${variant.handle}`;
			handle = `${handle}${variantHandle}`;
		}
		const mappedItem = {
			id: variant.sku,
			name: item?.title,
			handle,
			brand: "Mod Lighting",
			category: item?.type || item?.product_type || "",
			variant: variant.title,
			variant_id: variant.id?.toString(),
			price: variant.price?.toString() || "0.0",
			total_price: (item?.quantity || 1) * (variant.price || 0),
			quantity: item?.quantity?.toString() || "1",
			position: item.index || i + 1,
			product_id: item.id?.toString(),
			compare_at_price: item?.variant?.compareAtPrice?.toString() || "0.0",
			url: SITE_URL + handle
		};
		if (list) mappedItem.list = list;
		if (isProduct) mappedItem.image = item?.previewImageUrl || item?.image?.src || "";

		return mappedItem;
	});

export const mapProducts = (products, list) => mapItems(products, list, true);

export const mapImpressions = (impressions, list) => mapItems(impressions, list, false);

export const mapUserProperties = (user) => {
	const properties = {
		// The following fields are required 
		user_consent: "",
		visitor_type: user.isAuthenticated ? "logged_in" : "guest"
	};
	if (user.id) {
		properties.customer_tags = user.tags?.join(",");
		properties.customer_id = user.id;
		properties.customer_email = user.email;
		properties.first_name = user.firstName;
		properties.last_name = user.lastName;
		properties.customer_order_count = user.numberOfOrders;
	}
	if (user.defaultAddress) {
		properties.customer_address_1 = user.defaultAddress.address1;
		properties.customer_address_2 = user.defaultAddress.address2;
		properties.customer_city = user.defaultAddress.city;
		properties.customer_country = user.defaultAddress.country;
		properties.customer_province = user.defaultAddress.province;
		properties.customer_zip = user.defaultAddress.zip;
	}

	return properties;
};