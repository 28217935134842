import axios from "axios";
import qs from "qs";
import { SWYM_DATA } from "utils/constants";

export const axiosInstance = axios.create({
	baseURL: process.env.GATSBY_SWYM_BASE_URL,
});

axiosInstance.interceptors.request.use(
	async function (config) {
		const data = qs.parse(config.data);
		const regAndSessionId = await getRegAndSessionId();
		if (!regAndSessionId) {
			throw new Error("Reg & Session id not found");
		}
		config.headers = {
			"Content-Type": "application/x-www-form-urlencoded",
			"Accept": "application/json"
		};
		config.data = qs.stringify({ ...data, ...regAndSessionId });
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);


const API_BASE_URL = "api/v3/lists";
const PID = encodeURIComponent(process.env.GATSBY_SWYM_PID);

const handleResponse = (res) => {
	if (res.status === 200) {
		return res.data;
	} else {
		console.error(res);
		return { error: true };
	}
};

const handleError = (error) => {
	console.error(error);
	return { error: true };
};

/*
	@author: swym
	@notice: get localstorage data related to swym
	@dev:    get regid and sessionid.
	@return: regid - used for all swym API calls
	@return: sessionid - used for all swym API calls
*/

export const getRegAndSessionId = async () => {
	const swymData = localStorage.getItem(SWYM_DATA);
	if (swymData) {
		const { regid, sessionid } = JSON.parse(swymData);
		return { regid, sessionid };
	} else {
		try {
			const { data } = await axios.get("/.netlify/functions/generate-regid");
			if (data && data.success) {
				localStorage.setItem(SWYM_DATA, JSON.stringify(data.data));
				return { regid: data.data.regid, sessionid: data.data.sessionid };
			} else {
				return null;
			}
		} catch (error) {
			return handleError(error);
		}
	}
};

const postRequest = async (url, data) => {
	try {
		const res = await axiosInstance.post(`${API_BASE_URL}/${url}?pid=${PID}`, data);
		return handleResponse(res);
	} catch (error) {
		return handleError(error);
	}
};

/*
	@author: swym
	@notice: fetchlist
	@dev:    update list
	@param:  lid = list id
*/

export const fetchListContents = async (lid) => {
	const data = qs.stringify({
		"lid": lid,
	});
	return await postRequest("fetch-list-with-contents", data);
};


/*
	@author: swym
	@notice: to update multiple list
	@dev:    update list to delete or add a variant
	@param:  listsToAdd = array of list ids to add variant
	@param:  listsToRemove = array of list ids to remove variant
	@param:  empi = product id
	@param:  epi = variant id
	@param:  du = product url
	@return: d = array of variants delete from list
*/

export const updateLists = async (listsToAdd, listsToRemove, empi, epi, du) => {
	const data = qs.stringify({
		"a": `[${listsToAdd.map(lid => `"${lid}"`).join(",")}]`,
		"d": `[${listsToRemove.map(lid => `"${lid}"`).join(",")}]`,
		"listItem": `{ "epi": ${epi}, "empi": ${empi}, "du": "${du}"}`
	});
	return await postRequest("update-multiple-ctx", data);
};

/*
	@author: swym
	@notice: to update list
	@dev:    update list
	@param:  lid = list id
	@param:  lid = list name
*/

export const updateList = async (lid, lname, lnote) => {
	const data = qs.stringify({
		"lid": lid,
		"lname": lname,
		"lnote": lnote,
	});
	return await postRequest("update", data);
};

/*
	@author: swym
	@notice: to delete list
	@dev:    delete list
	@param:  lid = list id
*/

export const deleteList = async (lid) => {
	const data = qs.stringify({ "lid": lid });
	return await postRequest("delete-list", data);
};

/*
	@author: swym
	@notice: to delete a variant to wishlist
	@dev:    update list to delete a variant
	@param:  lid = list id
	@param:  empi = product id
	@param:  epi = variant id
	@param:  du = product url
	@return: d = array of variants delete from list
*/

export const deleteVariantFromList = async (lid, empi, epi, du) => {
	const data = qs.stringify({
		"lid": lid,
		"d": `[{ "epi": ${epi}, "empi": ${empi}, "du": "${du}"}]`
	});
	return await postRequest("update-ctx", data);
};

/*
	@author: swym
	@notice: to create a new list
	@dev:    create a new wishlist
	@param:  listName = name of the wishlist
	@return: object = created list data
*/

export const createLists = async (listsToAdd) => {
	const data = qs.stringify({ "lists": `[${listsToAdd.map(list => `{"lname":"${list.name}"}`).join(",")}]` });
	return await postRequest("create-multiple", data);
};

/*
	@author: swym
	@notice: to fetch all list for the user
	@dev:    fetches all list
	@return: array of lists
*/

export const getAllLists = async () => {
	return await postRequest("fetch-lists", {});
};

/*
	@author: swym
	@notice: to sync user email after sign in
	@dev:    syncs user email after sign in
	@param:  email = email of the user
*/

export const syncUserEmail = async (email) => {
	try {
		const { regid } = await getRegAndSessionId();
		const { data } = await axios.post("/.netlify/functions/sync-useremail-regid", { email, regid });
		if (data && data.success) {
			const swymData = JSON.parse(localStorage.getItem(SWYM_DATA));
			swymData.regid = data.data.regid;
			localStorage.setItem(SWYM_DATA, JSON.stringify(swymData));
		}
	} catch (error) {
		return handleError(error);
	}
};

/*
	@author: swym
	@notice: to sync user email after sign in
	@dev:    syncs user email after sign in
	@param:  email = email of the user
*/

export const shareWishlistViaEmail = async (lid, toemail, fromname) => {
	const data = qs.stringify({ lid, toemail, fromname });
	return await postRequest("emailList", data);
};

/*
	@author: swym
	@notice: to sync user email after sign in
	@dev:    syncs user email after sign in
	@param:  email = email of the user
*/

export const markListPublic = async (lid) => {
	const data = qs.stringify({ lid });
	return await postRequest("markPublic", data);
};