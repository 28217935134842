import axios from "axios";
import { extractIdFromGid } from "helpers/extract-gid";
import { loyaltyLionAccessToken } from "utils/constants";

const getLoyaltyLionCustomer = async (user) => {
	const loyaltyLionToken = JSON.parse(localStorage.getItem(loyaltyLionAccessToken) ?? null);
	const { email, id } = user;

	if (new Date(loyaltyLionToken?.expiresAt) > Date.now())
		return loyaltyLionToken;

	try {
		const response = await axios.get(
			`/.netlify/functions/generate-loyalty-lion-token?customerId=${extractIdFromGid(id)}
			&customerEmail=${email}`);
		localStorage.setItem(loyaltyLionAccessToken, JSON.stringify(response.data));
		return response.data;
	} catch (err) {
		console.error(err);
	}
};


export default getLoyaltyLionCustomer;
