import * as swymApi from "services/api/swym";

export const updateListContents = async (set, get) => {
	try {
		const listsSynced = get().wishlist.listsSynced;
		if (listsSynced) return;

		const data = await swymApi.getAllLists();

		if (!data.error) {
			const listItems = data.flatMap((c) => c.listcontents);

			const listsObject = data.reduce((acc, list) => ({ ...acc, [list.lid]: list }), {});

			set(state => {
				state.wishlist.savedLists = listsObject;
				state.wishlist.savedListItems = listItems;
				state.wishlist.listsSynced = true;
			}, false, "FETCH_WISHLISTS");
		} else {
			console.error("Error in fetching list items");
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateLists = async (set, listsToAdd, listsToRemove, empi, epi, du) => {
	try {
		const data = await swymApi.updateLists(listsToAdd, listsToRemove, empi, epi, du);

		if (!data.errors) {
			set(state => {
				const newItemsToAdd = data.a.filter(newItem =>
					!state.wishlist.savedListItems.some(item =>
						newItem.empi === item.empi &&
						newItem.lid === item.lid
					)
				);

				const updatedListItems = state.wishlist.savedListItems.filter(item =>
					!data.d.some(deleteItem =>
						deleteItem.empi === item.empi &&
						deleteItem.lid === item.lid
					)
				);

				const updatedSavedLists = {
					...state.wishlist.savedLists,
					...data.a.reduce((acc, item) => {
						const listcontents = [...(state.wishlist.savedLists[item.lid]?.listcontents || [])];
						if (!listcontents.some(element => element.empi === item.empi)) {
							listcontents.push(item);
						}
						return { ...acc, [item.lid]: { ...state.wishlist.savedLists[item.lid], listcontents } };
					}, {}),
					...data.d.reduce((acc, item) => {
						const listcontents =
							(state.wishlist.savedLists[item.lid]?.listcontents || []).filter(i => i.epi !== item.epi);
						return { ...acc, [item.lid]: { ...state.wishlist.savedLists[item.lid], listcontents } };
					}, {})
				};

				state.wishlist.savedLists = updatedSavedLists;
				state.wishlist.savedListItems = [...updatedListItems, ...newItemsToAdd];

			}, false, "UPDATE_WISHLISTS");
			return data;
		}
		else {
			return data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const createLists = async (set, newLists) => {
	try {
		const res = await swymApi.createLists(newLists);

		if (res[0].di) {
			set(state => {
				const lists = res.map(list => {
					return {
						...list,
						listcontents: []
					};
				});
				const updatedSavedLists = {
					...lists.reduce((acc, list) => {
						return { ...acc, [list.lid]: list };
					}, {}),
					...state.wishlist.savedLists
				};
				state.wishlist.savedLists = updatedSavedLists;

			}, false, "CREATE_WISHLISTS");
			return res;
		} else {
			return { message: "error" };
		}

	} catch (error) {
		console.error(error);
	}
};

export const saveForLater = async (get, empi, epi, du) => {
	try {
		const listName = "Saved for later";
		const savedLists = get().wishlist.savedLists;

		const existingList = Object.values(savedLists).find(list => list.lname.toLowerCase() === listName.toLowerCase());

		if (!existingList) {
			const list = await get().wishlist.createLists([{ name: listName }]);
			get().wishlist.updateLists([list[0].lid], [], empi, epi, du);
		} else {
			get().wishlist.updateLists([existingList.lid], [], empi, epi, du);
		}

		return { success: true };

	} catch (error) {
		console.error(error);
	}
};

export const updateListDescription = async (set, lid, lname, lnote) => {
	try {
		const res = await swymApi.updateList(lid, lname, lnote);

		if (res.errors) {
			throw new Error("Error occurred while updating list");
		}

		set(state => {
			state.wishlist.savedLists[lid].lname = lname;
			state.wishlist.savedLists[lid].lnote = lnote;
		}, false, "UPDATE_WISHLIST_DESCRIPTION");

		return res;
	} catch (error) {
		console.error(error);
	}
};

export const removeVariantFromList = async (set, lid, empi, epi, du) => {
	try {
		const res = await swymApi.deleteVariantFromList(lid, empi, epi, du);

		if (res.errors) {
			throw new Error("Error occurred while removing variant from list");
		}

		set(state => {
			const updatedListContents = (state.wishlist.savedLists[lid]?.listcontents || []).filter(item => item.epi !== epi);

			state.wishlist.savedLists[lid].listcontents = updatedListContents;
			state.wishlist.savedListItems = state.wishlist.savedListItems.filter(item => item.epi !== epi || item.lid !== lid);
		}, false, "REMOVE_VARIANT_FROM_LIST");

		return res;
	} catch (error) {
		console.error(error);
	}
};


export const removeList = async (set, lid) => {
	try {
		const res = await swymApi.deleteList(lid);

		if (res.errors)
			throw new Error("Error occurred while removing list");

		set(state => {
			delete state.wishlist.savedLists[lid];
			state.wishlist.savedListItems = state.wishlist.savedListItems.filter(item => item.lid !== lid);
		}, false, "REMOVE_WISHLIST");

		return res;
	} catch (error) {
		console.error(error);
	}
};

export const syncUserWishlist = async (set, email) => {
	try {
		if (!email) return;
		await swymApi.syncUserEmail(email);
		set(state => {
			state.wishlist.listsSynced = false;
		}, false, "SYNC_WISHLIST");
	} catch (error) {
		console.error(error);
	}
}; 