import {
	cartQuery, cartCreateMutation, cartLinesAddMutation, cartLinesUpdateMutation, cartLinesRemoveMutation,
	cartBuyerIdentityUpdateMutation
} from "./queries";
import handleCartMutation from "./handle-cart-mutation";
import { getAccessToken } from "./customer-resource";
import getCurrency from "helpers/get-currency";


import { PREDEFINED_MARKETS } from "utils/constants";

class CartResource {
	constructor(client, market = "US") {
		this.client = client;
		if (typeof window !== "undefined" && window?.__COUNTRY in PREDEFINED_MARKETS) this.market = window.__COUNTRY;
		else this.market = market;
	}

	fetch(id) {
		return this.client.send(cartQuery(this.market), { id });
	}

	create(cartInput = {}) {
		return this.client.send(cartCreateMutation(this.market), { cartInput })
			.then(handleCartMutation);
	}

	addLineItems(cartId, lines) {
		return this.client.send(cartLinesAddMutation(this.market), { cartId, lines })
			.then(handleCartMutation);
	}

	updateLineItems(cartId, lines) {
		return this.client
			.send(cartLinesUpdateMutation(this.market), { cartId, lines })
			.then(handleCartMutation);
	}

	removeLineItems(cartId, lineIds) {
		return this.client
			.send(cartLinesRemoveMutation(this.market), { cartId, lineIds })
			.then(handleCartMutation);
	}

	buyerIdentityUpdate(cartId) {
		const countryCode = getCurrency().countryCode;
		const customerAccessToken = getAccessToken();

		return this.client.send(cartBuyerIdentityUpdateMutation(this.market), {
			cartId,
			buyerIdentity: { customerAccessToken, countryCode }
		});
	}
}

export default CartResource;