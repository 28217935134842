import { extractIdFromGid } from "./extract-gid";
import initPushOwl from "scripts/initPushOwl";

export const pushOwlHandler = (customerId) => {
	const isDesktop = window.innerWidth >= 992;
	if(!isDesktop) return;
	// Trigger the PushOwl Opt-in Flow
	if (!window.pushowl) 
		initPushOwl();

	if (window.pushowl) {

		window.pushowl.trigger("showWidget", {
			type: "optinFlows",
		});

		customerId && window.pushowl.trigger("setCustomerId", extractIdFromGid(customerId));
	}
};