import {
	fetchCustomerProfile, fetchCustomerAddresses, createNewCustomerAddress,
	changeDefaultAddress, fetchCustomerOrders, updateCustomerAddress, deleteCustomerAddress
} from "actions/customer";
import { customerLogin, customerRegister, registerCustomerWithTags } from "actions/auth";
import { customerSessionKey, customerTokenKey, loyaltyLionAccessToken, CUSTOMER_STATE, SWYM_DATA } from "utils/constants";

const initialState = {
	displayName: "",
	isAuthenticated: undefined,
	firstName: "",
	lastName: "",
	email: "",
	pointsApproved: 0,
	pointsPending: 0,
	numberOfOrders: 0,
	createdAt: "",
	addresses: {
		nodes: {},
		pageInfo: {}
	},
	defaultAddress: null,
	customerState: CUSTOMER_STATE.Guest,
	id: "",
	orders: {},
	tags: [],
	error: null
};

const customerSlice = (set, get) => ({
	// Properties
	...initialState,

	// Actions
	fetchCustomerProfile: () => fetchCustomerProfile(set, get),

	fetchCustomerOrders: async (customerId, cursor) => await fetchCustomerOrders(set, get, customerId, cursor),

	fetchCustomerAddresses: (cursor) => fetchCustomerAddresses(set, get, cursor),

	createNewCustomerAddress: (address) => createNewCustomerAddress(set, get, address),

	changeDefaultAddress: (addressId) => changeDefaultAddress(set, get, addressId),

	updateCustomerAddress: (address, cleanId, isDefault) => updateCustomerAddress(set, get, address, cleanId, isDefault),

	deleteCustomerAddress: (addressId, isDefault) => deleteCustomerAddress(set, get, addressId, isDefault),

	updateCustomer: async (input) => {
		const shopifyClient = get().shopifyClient;
		const data = await shopifyClient.customer.update(input);
		return data;
	},

	resetCustomerPassword: async (email) => {
		const shopifyClient = get().shopifyClient;
		const data = await shopifyClient.customer.resetPassword(email);
		return data;
	},

	resetCustomerPasswordWithUrl: async (resetUrl, password) => {
		const shopifyClient = get().shopifyClient;
		const data = await shopifyClient.customer.resetPasswordWithUrl(resetUrl, password);
		return data;
	},

	activateCustomerByUrl: async (activationUrl, password) => {
		const shopifyClient = get().shopifyClient;
		const data = await shopifyClient.customer.activateByUrl(activationUrl, password);
		return data;
	},

	// actions
	signIn: (input) => customerLogin(set, get, input),

	signUp: (input) => customerRegister(set, get, input),

	logOut: () => {
		window.localStorage.removeItem(customerTokenKey);
		window.sessionStorage.removeItem(customerSessionKey);
		window?.loyaltylion?.logoutCustomer?.();
		window.localStorage.removeItem(loyaltyLionAccessToken);
		window.localStorage.removeItem(SWYM_DATA);
		get().wishlist.cleanData();
		set(() => ({ customer: { ...get().customer, ...initialState } }), false, "LOG_OUT");
	},

	registerCustomerWithTags: (input, fieldsToUpdate) =>
		registerCustomerWithTags(set, get, input, fieldsToUpdate),

});

export default customerSlice;