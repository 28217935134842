import { REBUY_API_KEY } from "utils/constants";
import { rebuyUniqeId } from "utils/constants";
import { extractIdFromGid } from "helpers/extract-gid";

const getOrGenerateUniqueId = () => {
	const localId = localStorage.getItem(rebuyUniqeId);
	if (localId) return localId;
	const newId = window.crypto.randomUUID();
	localStorage.setItem(rebuyUniqeId, newId);
	return newId;
};

const rebuyApiCall = async (endpoint, params) => {
	const baseUrl = "https://rebuyengine.com/api/v1/";
	const url = `${baseUrl}${endpoint}`;

	try {
		const response = await fetch(`${url}?${new URLSearchParams(params)}`);
		if (!response.ok) {
			throw new Error(`Request failed with status ${response.status}`);
		}
		const jsonResponse = await response.json();
		return jsonResponse.data;
	} catch (error) {
		console.error(`Error fetching data from ${endpoint}:`, error);
		return [];
	}
};


export const fetchRecommendedProducts = async (cart, customerId) => {
	const items = cart.items || [];
	if (!cart?.id || !items.length) return [];
	const shopify_product_ids = [...new Set(items.map(item => item.id))];
	const shopify_variant_ids = items.map(item => item.variant.id);

	const params = {
		key: REBUY_API_KEY,
		limit: 4,
		shopify_product_ids: shopify_product_ids.join(","),
		shopify_variant_ids: shopify_variant_ids.join(","),
		shopify_customer_id: customerId ? extractIdFromGid(customerId) : "",
		uuid: getOrGenerateUniqueId(),
		cart_subtotal: cart.totalPrice,
		cart_count: shopify_product_ids.length,
		cart_line_count: shopify_product_ids.length,
		cart_item_count: shopify_variant_ids.length,
		"cart[subtotal]": cart.totalPrice,
		"cart[line_count]": shopify_product_ids.length,
		"cart[item_count]": shopify_variant_ids.length,
		...cart.items.reduce((acc, item, index) => {
			acc[`cart[items][${index}][product_id]`] = item.id;
			acc[`cart[items][${index}][variant_id]`] = item.variant.id;
			acc[`cart[items][${index}][quantity]`] = item.quantity;
			return acc;
		}, {}),
		presentment_prices: "yes",
		product_groups: "yes",
	};

	return rebuyApiCall("custom/id/104255", params);
};

// Fetch similar products based on a given product ID
export const fetchSimilarProducts = (productId) => {
	const params = {
		key: REBUY_API_KEY,
		shopify_product_ids: productId,
		limit: 4,
		presentment_prices: "yes",
	};

	return rebuyApiCall("custom/id/104255", params);
};

// Fetch products by their IDs
export const fetchProductsByIds = (productIds) => rebuyApiCall("products/static", {
	key: REBUY_API_KEY,
	ids: productIds,
	limit: 10
});