import { PREDEFINED_MARKETS, EUR_COUNTRIES, countryCodeKey } from "utils/constants";

const setCookie = (name, value, hours) => {
	document.cookie = `${name}=${value}; expires=${new Date(Date.now() + hours * 3600 * 1000).toUTCString()}; path=/`;
};

const getCookie = (name) => document.cookie.split("; ").find(c => c.startsWith(name))?.split("=")[1];

const resolveCountryCode = (country) => {
	if (country in PREDEFINED_MARKETS) return country;
	if (EUR_COUNTRIES.has(country)) return "DE";
	return null;
};

const getValidCountry = () => {
	const urlCountry = new URLSearchParams(window.location.search).get("country")?.toUpperCase();
	const validCountry = resolveCountryCode(urlCountry);

	if (validCountry) {
		setCookie(countryCodeKey, validCountry, 1);
		return validCountry;
	}
	return getCookie(countryCodeKey);
};

const getCurrency = () => {
	if (typeof window === "undefined") {
		return { code: "USD", symbol: "$", countryCode: "US" };
	}

	const validCountry = getValidCountry() || (window.__COUNTRY in PREDEFINED_MARKETS ? window.__COUNTRY : "US");

	// Get the market data or default to USD
	const { currency = "USD", symbol = "$" } = PREDEFINED_MARKETS[validCountry] || {};

	return { code: currency, symbol, countryCode: validCountry };
};

export default getCurrency;
