const handleCartMutation = (data) => {
	if (data && data.cart) {
		return Promise.resolve(data.cart);
	}

	if (data && data.userErrors.code && data.userErrors.message) {
		return Promise.reject(new Error(JSON.stringify(data.userErrors)));
	}

	return Promise.reject(new Error(`The mutation failed due to an unknown error.`));
};

export default handleCartMutation;
