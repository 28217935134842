import { customerSessionKey, customerTokenKey, MAIL_ID_REGEX, CUSTOMER_STATE } from "utils/constants";
import getLoyaltyLionCustomer from "services/loyalty-lion-customer";

export const setCustomerAccessToken = (token) =>
	window.localStorage.setItem(customerTokenKey, !token ? "" : JSON.stringify(token));

const fetchLoyaltyCustomer = async (user, set) => {
	const data = await getLoyaltyLionCustomer(user);

	if (data?.id) {
		window.loyaltylion &&
			await window.loyaltylion?.authenticateCustomer({
				customer: {
					id: data.id,
					email: data.email,
				},
				auth: {
					date: data.date,
					token: data.token,
				},
			});


		const interval = setInterval(() => {
			if (window.loyaltylion?.customer) {
				clearInterval(interval);
				// Add here any other data you want to pass to the loyaltylion customer
				const { pointsApproved, pointsPending } = window.loyaltylion?.customer ?? {};
				const payload = { pointsApproved, pointsPending };
				set(state => {
					state.customer.pointsApproved = payload.pointsApproved;
					state.customer.pointsPending = payload.pointsPending;
				}, false, "FETCH_LOYALTY_LION_DATA");
			}
		}, 50);
	}
};

export const fetchCustomerProfile = async (set, get) => {
	const shopifyClient = get().shopifyClient;
	const storedUser = window.sessionStorage.getItem(customerSessionKey);
	const user = storedUser ? JSON.parse(storedUser) : await shopifyClient.customer.getProfile();

	if (!user) {
		set(state => {
			state.customer.isAuthenticated = false;
			state.customer.customerState = CUSTOMER_STATE.Logged_out;
		}, false, "NO_USER_FOUND");
		return {
			isAuthenticated: false,
			customerState: CUSTOMER_STATE.Logged_out,
		};
	}

	set(state => {
		state.customer = { ...state.customer, ...user };
		state.customer.isAuthenticated = true;
		state.customer.customerState = CUSTOMER_STATE.Logged_in;
	}, false, "CUSTOMER_FETCHED");

	fetchLoyaltyCustomer(user, set);
	return {
		...user,
		isAuthenticated: true,
	};
};

export const fetchCustomerAddresses = async (set, get, cursor = null) => {
	try {
		const shopifyClient = get().shopifyClient;
		const customer = await shopifyClient.customer.getPersonalAddresses(cursor);

		if (customer) {
			const { addresses, defaultAddress } = customer;

			const mappedAddresses = Object.fromEntries(customer?.addresses?.nodes
				.map((node) => [node.id.match(MAIL_ID_REGEX)[1], node]));

			set(state => {
				state.customer.addresses.nodes = { ...state.customer.addresses.nodes, ...mappedAddresses };
				state.customer.addresses.pageInfo = addresses.pageInfo;
				state.customer.defaultAddress = defaultAddress;
			}, false, "FETCH_CUSTOMER_ADDRESSES");

		}
	} catch (error) {
		console.error("Error while setting customer addresses:", error);
	}
};

export const createNewCustomerAddress = async (set, get, payload) => {
	try {
		const shopifyClient = get().shopifyClient;
		const { makeDefault, ...address } = payload;
		const response = await shopifyClient.customer.createAddress(address);

		const errors = response?.customerUserErrors || response?.errors;

		if (errors?.length)
			return { success: false, message: errors[0].message };

		const new_address = response?.customerAddress;
		const cleanId = new_address?.id?.match(MAIL_ID_REGEX)[1];

		set(state => {
			state.customer.addresses.nodes[cleanId] = new_address;
		}, false, "CREATE_CUSTOMER_ADDRESS");

		let success = true;
		if (makeDefault)
			success = (await changeDefaultAddress(set, get, new_address.id)).success;

		if (!success)
			throw new Error();

		return { success: true, message: "Address created successfully" };
	} catch (error) {
		return { success: false, message: "Error while creating customer address" };
	}
};

export const changeDefaultAddress = async (set, get, addressId) => {
	try {
		const shopifyClient = get().shopifyClient;
		const response = await shopifyClient.customer.changeDefaultAddress(addressId);
		const errors = response?.customerUserErrors || response?.errors;

		if (errors?.length)
			return { success: false, message: errors[0].message };

		set(state => { state.customer.defaultAddress = response.customer.defaultAddress; }, false, "UPDATE_DEFAULT_ADDRESS");

		return { success: true };
	} catch (error) {
		console.error("Error while updating customer default address:", error);
		return { success: false, message: "Error while updating customer default address" };
	}
};

export const updateCustomerAddress = async (set, get, payload, cleanId, isDefault) => {
	try {
		const shopifyClient = get().shopifyClient;
		const { id, makeDefault, ...address } = payload;
		const response = await shopifyClient.customer.updateAddress(id, address);

		const errors = response?.customerUserErrors || response?.errors;

		if (errors?.length)
			return { success: false, message: errors[0].message };

		const updatedAddress = response.customerAddress;

		set(state => {
			state.customer.addresses.nodes[cleanId] = updatedAddress;
		}, false, "UPDATE_CUSTOMER_ADDRESS");

		if (isDefault)
			set(state => { state.customer.defaultAddress = updatedAddress; }, false, "UPDATE_DEFAULT_ADDRESS");

		let success = true;
		if (makeDefault)
			success = (await changeDefaultAddress(set, get, updatedAddress.id)).success;

		if (!success)
			throw new Error();

		return { success: true, message: "Address updated successfully" };
	} catch (error) {
		return { success: false, message: "Error while updating customer address" };
	}
};

export const deleteCustomerAddress = async (set, get, addressId, cleanId) => {
	try {
		const shopifyClient = get().shopifyClient;
		const response = await shopifyClient.customer.deleteAddress(addressId);

		const errors = response?.customerUserErrors || response?.errors;

		if (errors?.length)
			return { success: false, message: errors[0].message };

		set(state => {
			delete state.customer.addresses.nodes[cleanId];
		}, false, "DELETE_CUSTOMER_ADDRESS");

		return { success: true, message: "Address deleted successfully" };
	} catch (error) {
		return { success: false, message: "Error while deleting customer address" };
	}
};

export const fetchCustomerOrders = async (set, get, customerId, cursor = null) => {
	try {
		const shopifyClient = get().shopifyClient;
		const response = await shopifyClient.customer.getOrdersFromAdmin(customerId, cursor);

		const payload = {
			nodes: response?.orders,
			pageInfo: response?.pageInfo,
		};


		if (response?.orders?.length && !response?.customerUserErrors?.length) {
			return set(state => {
				state.customer.orders = !cursor ? payload : {
					nodes: [...state.customer.orders.nodes, ...payload.nodes],
					pageInfo: payload.pageInfo,
				};
			}, false, "FETCH_ORDERS");
		}
	} catch (error) {
		console.error("Error while fetching customer orders:", error);
	}
};
