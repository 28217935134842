import { getUrlParam, normalizeFilterParam } from "helpers/url-params";

export const sortProducts = (products, sort) => {
	const productsCopy = [...products];
	switch (sort) {
		case "manual":
			return products;
		case "best-selling":
			return productsCopy.sort((a, b) => b.orderedQuantity - a.orderedQuantity);
		case "title-ascending":
			return productsCopy.sort((a, b) => a.title.localeCompare(b.title));
		case "title-descending":
			return productsCopy.sort((a, b) => b.title.localeCompare(a.title));
		case "price-ascending":
			return productsCopy.sort((a, b) => a.minVariantPrice - b.minVariantPrice);
		case "price-descending":
			return productsCopy.sort((a, b) => b.minVariantPrice - a.minVariantPrice);
		case "created-ascending":
			return productsCopy.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
		case "created-descending":
			return productsCopy.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		default:
			return productsCopy.sort((a, b) => b.orderedQuantity - a.orderedQuantity);
	}
};

export const mapActiveFilters = (tags) => {
	const keysArray = {};
	for (const [key, value] of Object.entries(tags || {})) {
		keysArray[key] = Object.keys(value).filter(tag => value[tag]);
	}
	return keysArray;
};

export const filterProducts = (products, { tags, priceRange }, filters = []) => {
	const activeFilters = filters.length ? filters : Object.values(mapActiveFilters(tags)).flat();

	const minPrice = priceRange?.min || 0;
	const maxPrice = priceRange?.max || Infinity;

	const filteredProducts = products.map(product => {
		const { minVariantPrice, tags: productTags = [] } = product;
		const isPriceInRange = minVariantPrice >= minPrice && minVariantPrice <= maxPrice;
		const isTagsMatch = activeFilters?.length ? productTags?.some(tag => activeFilters.includes(tag)) : true;
		const variant = product.variants?.find(variant => variant.tags?.some(tag => activeFilters.includes(tag)));

		if ((isTagsMatch || variant) && isPriceInRange) {
			if (variant)
				return { ...product, variant };
			return product;
		} else
			return false;
	}).filter(Boolean);

	return filteredProducts;
};

export const mapFilterTags = (filters) => {
	if (!filters) return null;

	const mappedFilters = filters.reduce((acc, item) => {
		acc[item.name] = item.data.reduce((tags, tagItem) => {
			tags[tagItem.tag] = false;
			return tags;
		}, {});
		return acc;
	}, {});

	Object.keys(mappedFilters).forEach(category => {
		const urlTags = getUrlParam(normalizeFilterParam(category));
		if (urlTags) urlTags.split(",").forEach(t => {
			if (Object.hasOwn(mappedFilters[category], t))
				mappedFilters[category][t] = true;
		});
	});

	return mappedFilters;
};
