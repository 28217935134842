import { customerTokenKey, customerSessionKey } from "utils/constants";
import axios from "axios";

//Queries
import {
	customerAccessTokenMutation, customerCreateMutation, customerRecoverMutation, customerResetByUrlMutation,
	activateCustomerByUrlMutation, getCustomerQuery, personalAddressesQuery, customerAddressUpdateMutation,
	customerAddressDeleteMutation, ordersQuery, changeDefaultAddressMutation, customerAddressCreateMutation,
	customerUpdateMutation
} from "./queries";

export const getAccessToken = () => {
	const token = JSON.parse(window.localStorage.getItem(customerTokenKey) || null);
	if (token?.accessToken && new Date() < new Date(token.expiresAt))
		return token.accessToken;

	return null;
};

export const withAccessToken = (callback) => {
	const token = getAccessToken();

	if (!token) return new Promise((resolve) => resolve(null));

	return callback(token);
};

class CustomerResource {
	constructor(client) {
		this.client = client;
	}

	update(input) {
		return withAccessToken(customerAccessToken => {
			return this.client.send(customerUpdateMutation, { input, customerAccessToken });
		});
	}

	createAccessToken(input) {
		return this.client.send(customerAccessTokenMutation, { input });
	}

	create(input) {
		return this.client.send(customerCreateMutation, { input });
	}

	resetPassword(email) {
		return this.client.send(customerRecoverMutation, { email });
	}

	resetPasswordWithUrl(resetUrl, password) {
		return this.client.send(customerResetByUrlMutation, { resetUrl, password });
	}

	activateByUrl(activationUrl, password) {
		return this.client.send(activateCustomerByUrlMutation, { activationUrl, password });
	}

	async getProfile() {
		return withAccessToken(async (token) => {
			const customer = await this.client.send(getCustomerQuery(token));
			if (customer) {
				this.setInSession(customer);
				return customer;
			}

			return null;
		});
	}


	getPersonalAddresses(cursor) {
		return withAccessToken((token) => {
			const afterCursorEl = cursor ? `after: "${cursor},"` : "";
			return this.client.send(personalAddressesQuery(token, afterCursorEl));
		});
	}

	updateAddress(id, address) {
		return withAccessToken(customerAccessToken => {
			return this.client.send(customerAddressUpdateMutation, {
				customerAccessToken,
				id,
				address,
			});
		});
	}

	createAddress(address) {
		return withAccessToken(customerAccessToken => {
			return this.client.send(customerAddressCreateMutation, {
				customerAccessToken,
				address,
			});
		});
	}

	deleteAddress(id) {
		return withAccessToken(customerAccessToken => {
			return this.client.send(customerAddressDeleteMutation, {
				customerAccessToken,
				id,
			});
		});
	}

	changeDefaultAddress(addressId) {
		return withAccessToken(customerAccessToken => {
			return this.client.send(changeDefaultAddressMutation, {
				addressId,
				customerAccessToken,
			});
		});
	}

	getOrders(cursor) {
		return withAccessToken(token => {
			const afterCursorEl = cursor ? `after: "${cursor},"` : "";
			return this.client.send(ordersQuery(token, afterCursorEl));
		});
	}

	getOrdersFromAdmin = async (customerId, cursor) => {
		const afterCursorEl = cursor ? `after: "${cursor},"` : "";

		try {
			return await withAccessToken(async () => {
				const response = await axios("/.netlify/functions/admin-api-orders", {
					method: "POST",
					data: {
						customerId,
						afterCursorEl,
					},
				});
				return response.data;
			});
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	updateFromAdmin = async (input) => {
		try {
			const response = await axios("/.netlify/functions/admin-api-customer-update", {
				method: "POST",
				data: {
					input,
				},
			});
			return response.data;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	setInSession(customer) {
		if (!customer) return;
		sessionStorage.setItem(customerSessionKey, JSON.stringify(customer));
	}

}

export default CustomerResource;