import {
	updateListContents, updateLists, createLists,
	removeVariantFromList, updateListDescription, removeList, syncUserWishlist,
	saveForLater
} from "actions/wishlist";

const initialState = {
	savedLists: [],
	savedListItems: [],
	listsSynced: false,
};

const wishlistSlice = (set, get) => ({
	// Properties
	...initialState,

	// Actions
	updateListContents: () => updateListContents(set, get),
	updateLists: (listsToAdd, listsToRemove, empi, epi, du) => updateLists(set, listsToAdd, listsToRemove, empi, epi, du),
	createLists: (newLists) => createLists(set, newLists),
	removeVariantFromList: (lid, empi, epi, du) => removeVariantFromList(set, lid, empi, epi, du),
	saveForLater: (empi, epi, du) => saveForLater(get, empi, epi, du),
	updateListDescription: (lid, lname, lnote) => updateListDescription(set, lid, lname, lnote),
	removeList: (lid) => removeList(set, lid),
	syncUserWishlist: (email) => syncUserWishlist(set, email),
	cleanData: () => {
		set(() => ({ wishlist: { ...get().wishlist, ...initialState } }), false, "CLEAN_WISHLIST_DATA");
	},
});

export default wishlistSlice;