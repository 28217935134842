/* eslint-disable max-lines */

export const CustomerUserErrorFragment = `
  fragment CustomerUserErrorFragment on CustomerUserError {
    code
    field
    message
  }
`;

export const CartUserErrorFragment = `
  fragment CartUserErrorFragment on CartUserError {
    code
    field
    message
  }
`;

export const MoneyV2Fragment = `
fragment MoneyV2Fragment on MoneyV2 {
  amount
  currencyCode
}`;

export const DiscountApplicationFragment = `
  ${MoneyV2Fragment}
  fragment DiscountApplicationFragment on DiscountApplication {
    allocationMethod
    targetSelection
    targetType
    value {
      ... on MoneyV2 {
        ...MoneyV2Fragment
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    ... on ManualDiscountApplication {
      title
      description
    }
    ... on DiscountCodeApplication {
      code
      applicable
    }
    ... on ScriptDiscountApplication {
      title
    }
    ... on AutomaticDiscountApplication {
      title
    }
  }
`;

export const AppliedGiftCardFragment = `
fragment AppliedGiftCardFragment on AppliedGiftCard {
  amountUsed {
    amount
    currencyCode
  }
  amountUsedV2: amountUsed {
    amount
    currencyCode
  }
  balance {
    amount
    currencyCode
  }
  balanceV2: balance {
    amount
    currencyCode
  }
  presentmentAmountUsed {
    amount
    currencyCode
  }
  id
  lastCharacters
}`;

export const MailingAddressFragment = `
  fragment MailingAddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    lastName
    phone
    province
    zip
  }`;



export const VariantFragment = `
fragment VariantFragment on ProductVariant {
  id
  title
  price {
    amount
    currencyCode
  }
  priceV2: price {
    amount
    currencyCode
  }
  weight
  available: availableForSale
  sku
  compareAtPrice {
    amount
    currencyCode
  }
  compareAtPriceV2: compareAtPrice {
    amount
    currencyCode
  }
  image {
    id
    src: url
    altText
    width
    height
  }
  selectedOptions {
    name
    value
  }
  unitPrice {
    amount
    currencyCode
  }
}`;

export const VariantWithProductFragment = `
  ${VariantFragment}
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      id
      handle
      title
      productType
      variants(first: 100) {
        nodes {
          title
          available: availableForSale
        }
      }
    }
  }`;

export const CartFragment = `
  ${VariantWithProductFragment}
  ${MoneyV2Fragment}
  fragment CartFragment on Cart {
    id,
    checkoutUrl,
    totalQuantity,
    createdAt,
    discountCodes {
      code
    }
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
      ... on CartAutomaticDiscountAllocation {
        discountedAmount {
          ...MoneyV2Fragment
        }
        title
      }
      ... on CartCodeDiscountAllocation {
        discountedAmount {
          ...MoneyV2Fragment
        }
        code
      }
      ... on CartCustomDiscountAllocation {
        discountedAmount {
          ...MoneyV2Fragment
        }
        title
      }
    }
    buyerIdentity {
      email
      countryCode
    },
    lines(first: 250) {
      nodes {
        id
        variant: merchandise {
          ... on ProductVariant {
            ...VariantWithProductFragment
          }
        }
        quantity
        attributes {
          key
          value
        }
        discountAllocations {
          discountedAmount {
            ...MoneyV2Fragment
          }
          ... on CartCodeDiscountAllocation {
            discountedAmount {
              ...MoneyV2Fragment
            }
            code
          }
          ... on CartCustomDiscountAllocation {
            discountedAmount {
              ...MoneyV2Fragment
            }
            title
          }
        }
      }
    }
  }
`;

export const CustomerAddressFragment = `
  fragment CustomerAddressFragment on MailingAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    lastName
    phone
    province
    formatted
    zip
  }
`;

export const PageInfoFragment = `
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
  }
`;

export const CustomerAccessTokenFragment = `
  fragment CustomerAccessTokenFragment on CustomerAccessToken {
    accessToken
    expiresAt
  }
`;