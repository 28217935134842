import "styles/global.css";
import { PRODUCTION_ENV, EUR_COUNTRIES } from "utils/constants";
import initLoyaltyLion from "scripts/initLoyaltyLion";
import useGlobalStore from "store"; 
import { pushOwlHandler } from "helpers/pushowl";

export const onClientEntry = () => {
	fetch("/api/geo").then(response => response.json()).then(data => {
		const countryCode = data.country.code
		window.__COUNTRY = EUR_COUNTRIES.has(countryCode) ? "DE" : countryCode;

	}).catch(error => {
		console.error("Error during onClientEntry:", error);
		window.__COUNTRY = PRODUCTION_ENV ? "US" : "CA";
	});
};

//Called when the user changes routes, including on the initial load of the app
export const onRouteUpdate = async ({ location }) => {
	const customerId = useGlobalStore.getState().customer?.id;

	if (!window.loyaltylion && location.pathname !== "/") {
		!initLoyaltyLion(document, window.loyaltylion || []);
		window.loyaltylion.init({ token: `${process.env.GATSBY_LOYALTY_LION_TOKEN}` });
	}

    pushOwlHandler(customerId);

	if (PRODUCTION_ENV)
		window.ElevarInvalidateContext?.();
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => !location?.state?.preserveScroll;