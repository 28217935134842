import { create } from "zustand";
import { devtools } from "zustand/middleware";
import profileSlice from "slices/customer";
import settingsSlice from "slices/settings";
import cartSlice from "slices/cart";
import wishlistSlice from "slices/wishlist";
import filtersSlice from "slices/filters";
import Client from "shopify/client";
import { PRODUCTION_ENV } from "utils/constants";
import { immer } from "zustand/middleware/immer";

const useGlobalStore = create(
	immer(
		devtools((...a) => ({
			customer: profileSlice(...a),
			settings: settingsSlice(...a),
			cart: cartSlice(...a),
			wishlist: wishlistSlice(...a),
			filters: filtersSlice(...a),
			shopifyClient: new Client(),
		}), { enabled: !PRODUCTION_ENV })
	)
);

export default useGlobalStore;
