import { CUSTOMER_STATE } from "utils/constants";
import { trackSignup, trackLogin } from "analytics";
import { setCustomerAccessToken } from "./customer";

export const customerLogin = async (set, get, input) => {
	try {
		const shopifyClient = get().shopifyClient;
		const response = await shopifyClient.customer.createAccessToken(input);
		const { customerUserErrors, customerAccessToken, errors = [] } = response;

		if (errors?.length > 0)
			return {
				error: true,
				message: errors[0].message
			};

		if (customerUserErrors?.length > 0) {
			const unknownCustomer = customerUserErrors.find((k) => k.code === "UNIDENTIFIED_CUSTOMER");
			return {
				error: true,
				message: unknownCustomer ? "Your log in credentials are incorrect" : customerUserErrors[0].message,
			};
		}

		if (customerAccessToken) {
			setCustomerAccessToken(customerAccessToken);
			set(state => ({
				customer: { ...state.customer, isAuthenticated: true, customerState: CUSTOMER_STATE.Logged_in }
			}), false, "CUSTOMER_AUTHENTICATED");

			const customer = await get().customer.fetchCustomerProfile();
			trackLogin({ ...customer, isAuthenticated: true });
			get().wishlist.syncUserWishlist(input.email);
			return { customer, error: false };
		}
	} catch (error) {
		console.error("Error during sign-in:", error);
		return {
			error: true,
			message: "Error during sign-in"
		};
	}
};

export const customerRegister = async (set, get, input) => {
	try {
		const shopifyClient = get().shopifyClient;
		const response = await shopifyClient.customer.create(input);
		const { customerUserErrors = [], customer, errors = [] } = response || {};

		// Check for shopify errors
		if (errors?.length)
			return { formError: true, fieldErrors: false, message: errors[0].message };

		if (customerUserErrors?.length) {
			// Check if the user is disabled
			const activationError = customerUserErrors.find((k) => k.code === "CUSTOMER_DISABLED");

			if (activationError)
				return { formError: true, fieldErrors: false, message: activationError.message };

			return { formError: false, fieldErrors: true, customerUserErrors };
		}
		trackSignup({ ...customer, isAuthenticated: true });
		customerLogin(set, get, { email: input.email, password: input.password });

		return { customer, formError: false, fieldErrors: false, message: "Account created successfully" };

	} catch (error) {
		console.error("Error during sign-in:", error);
		return { formError: true, fieldErrors: false, message: "Error during sign-in" };
	}
};

export const registerCustomerWithTags = async (set, get, input, fieldsToUpdate) => {
	try {
		const { isAuthenticated, email, id: currentCustomerId, tags: currentTags } = get().customer;
		let customerId = currentCustomerId;

		// Merge existing tags with new tags
		const mergedTags = mergeTags(currentTags, fieldsToUpdate.tags);
		fieldsToUpdate = { ...fieldsToUpdate, tags: mergedTags, firstName: input.firstName, lastName: input.lastName };

		if (!isAuthenticated || email !== input.email || !currentCustomerId) {
			// Try to register the customer if not authenticated
			const registerResponse = await customerRegister(set, get, input);

			if (registerResponse.formError || registerResponse.fieldErrors) {
				return handleRegistrationError(registerResponse, input, fieldsToUpdate, set, get);
			}

			customerId = registerResponse.customer?.id || null;
		}

		// Update the customer with the new tags and note
		const updateResponse = await updateCustomer(get().shopifyClient.customer, fieldsToUpdate, customerId);

		if (updateResponse.errors?.length) {
			return {
				success: false, companyFormError: true, companyFieldErrors: false,
				message: updateResponse.errors[0].message
			};
		}

		if (updateResponse.userErrors?.length) {
			return { companyFormError: false, companyFieldErrors: true, userErrors: updateResponse.userErrors };
		}

		get().shopifyClient.customer.setInSession(updateResponse.customer);
		set(state => ({
			customer: { ...state.customer, tags: updateResponse.customer.tags }
		}), false, "CUSTOMER_UPDATED");

		return { success: true, message: "Customer updated successfully" };

	} catch (error) {
		return { success: false, message: "Error while updating customer" };
	}
};

const mergeTags = (existingTags, newTags) => [...new Set([...existingTags, ...newTags])];

const handleRegistrationError = async (registerResponse, input, fieldsToUpdate, set, get) => {
	const existingEmailError = registerResponse.customerUserErrors?.find(error => error.code === "TAKEN");

	if (!existingEmailError) {
		return registerResponse;
	}

	// eslint-disable-next-line no-unused-vars
	const { email, password, acceptsMarketing, ...resData } = input;
	const loginResponse = await customerLogin(set, get, { email, password });

	if (loginResponse.error) {
		return { formError: true, fieldErrors: false, message: loginResponse.message };
	}

	const mergedTags = mergeTags(fieldsToUpdate.tags, loginResponse.customer.tags);

	fieldsToUpdate = {
		...(fieldsToUpdate.note && { note: fieldsToUpdate.note }),
		tags: mergedTags,
		...resData
	};

	const customerId = loginResponse.customer?.id;

	return { customerId, fieldsToUpdate };
};

const updateCustomer = async (shopifyClientCustomer, fieldsToUpdate, customerId) => {
	const response = await shopifyClientCustomer.updateFromAdmin({ ...fieldsToUpdate, id: customerId });

	const { userErrors = [], errors = [], customer } = response.data || {};
	return { userErrors, errors, customer };
};